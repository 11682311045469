import React, {useState} from "react";
import moment from 'moment';
import background from "../assets/img/bg_influence.jpg";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";

function CreateAction() {
    const uriTypeParam = "/actions_params";
    const uriToCreateAction = "/new_action";
    const [plateforms, setPlateforms] = useState(null);
    const [plateformSelected, setPlateformSelected] = useState({id: ""});
    const [campaign, setCampaign] = useState(null);
    const [campaignSelected, setCampaignSelected] = useState({id: ""});
    const [products, setProducts] = useState(null);
    const [productsSelected, setProductsSelected] = useState({id: ""});
    const [types, setTypes] = useState(null);
    const [typesSelected, setTypesSelected] = useState({id: ""});
    const [values, setValues] = React.useState({
        showElement: false,
    });
    let history = useHistory();

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");


    const [createAction, setCreateAction] = useState({
        idPlateform: "",
        idProduct: "",
        idCampaign: "",
        idActionType: "",
        pubDate: "",
        pubLink: "",
        pubDescription: "",
    });
    const token = localStorage.getItem('token');
    const contraId = localStorage.getItem('contraId');
    const editActionIntention = localStorage.getItem('editIntention');

    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    const apiUpload = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    React.useEffect(() => {
        getTypeParams();

        function getTypeParams() {
            api.get(uriTypeParam + "/" + contraId).then(
                res => {
                    console.log(res);
                    setCampaign(res.data.campagns);
                    setProducts(res.data.products);
                    setPlateforms(res.data.plateforms);
                    setTypes(res.data.types);
                }
            ).catch(err => {
                alert(err.response.data.message);
            })

        }

    }, []);

    const uriForUpload = "/new_actionfile";

    const [loadingUpload, setLoadingUpload] = useState(false);

    const [selectedFile, setSelectedFile] = useState({selectedFile: ''});

    const [errorUpload, setErrorUpload] = useState("");

    const handleInputChange = event => {
        setSelectedFile({...selectedFile, selectedFile: event.target.files[0]})
    }

    let actionId;
    const submitHandler = e => {
        e.preventDefault();
        setError("");
        setLoading(true);
        if (createAction.pubDate !== "" &&
            createAction.idProduct !== "" &&
            createAction.idActionType !== "" &&
            createAction.idCampaign !== "" &&
            createAction.idPlateform !== "") {
            const dataToCreateAction = {
                contract: contraId,
                platform: createAction.idPlateform,
                product: createAction.idProduct,
                campaign: createAction.idCampaign,
                action_type: createAction.idActionType,
                date_pub: createAction.pubDate,
                link: createAction.pubLink==="" ?"actionDetails":createAction.pubLink,
                text: createAction.pubDescription ===""?"R.A.S":createAction.pubDescription,
                tags: ""
            };
            console.log(dataToCreateAction);
            api.post(uriToCreateAction, dataToCreateAction).then(
                res => {
                    console.log(res);
                    setLoading(false);
                    actionId = res.data.action.id;
                    localStorage.setItem('actionId', actionId);
                    setCreateAction({
                        ...createAction,
                        pubDate: "",
                        pubDescription: "",
                        pubLink: "",
                    });
                    alert("Action créer sans fichier avec succès");
                    console.log('id: ' + actionId);
                    if (selectedFile.selectedFile !== "") {
                        submitHandlerForUpload();
                    } else{
                        history.replace('/listOfAction');
                    }
                }
            ).catch(err => {
                setError(err.response.data.message);
                alert(err.response.data.message);
                setLoading(false);
            })
        } else {
            setLoading(false);
            setError("Renseignez les champs vide !!!");
        }
    }

    function submitHandlerForUpload() {
        console.log('is there');
        const data = new FormData();
        const myActionId = localStorage.getItem("actionId");
        console.log(myActionId);
        console.log(selectedFile.selectedFile);
        if (myActionId !== "" &&
            selectedFile.selectedFile !== "") {
            data.append('file', selectedFile.selectedFile)
            data.append('action', myActionId);
            console.log(data);
            apiUpload.post(uriForUpload, data).then(
                res => {
                    console.log(res);
                    setLoadingUpload(false);

                    alert("Action créer avec succès");
                    setSelectedFile({...selectedFile, selectedFile: ""});
                    history.replace('/listOfAction');
                }
            ).catch(err => {
                setErrorUpload(err.response.data.message);
                alert(err.response.data.message);
                setLoadingUpload(false);
            })
        } else {
            setLoadingUpload(false);
            setErrorUpload("Renseignez votre les champs vide !!!");
        }
    }

    const stateSelectionHandlerCampaign = event => {
        const value = event.target.value;
        setCreateAction({...createAction, idCampaign: value});
        setCampaignSelected({...campaignSelected, id: value});
        const stateIndex = campaign.findIndex(state => state.value === value);
        console.log(event.target.value, campaign, "index:", stateIndex);

        console.log("id campaign: " + campaignSelected.id);
    };
    const stateSelectionHandlerProduct = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateAction({...createAction, idProduct: value});
        setProductsSelected({...productsSelected, id: value});
        const stateIndex = products.findIndex(state => state.value === value);
        console.log(event.target.value, products, "index:", stateIndex);
        console.log("id product: " + productsSelected.id);
    };

    const stateSelectionHandlerPlatform = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateAction({...createAction, idPlateform: value});
        setPlateformSelected({...plateformSelected, id: value});
        const stateIndex = plateforms.findIndex(state => state.value === value);
        console.log(event.target.value, plateforms, "index:", stateIndex);
        console.log("id product: " + plateformSelected.id);
    };
    const stateSelectionHandlerTypes = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateAction({...createAction, idActionType: value});
        setTypesSelected({...typesSelected, id: value});
        const stateIndex = types.findIndex(state => state.value === value);
        console.log(event.target.value, types, "index:", stateIndex);
        console.log("id product: " + typesSelected.id);
    };
    return (
        <>
            <Container fluid>
                <Row className="login_form">
                    <Col md="7">
                        <Card>
                                <Row>
                                    <Col className="font-icon-list" lg="1" md="2" sm="2" xs="3">
                                        <div className="font-icon-detail" onClick={() => {
                                            history.push('/contraDetails');
                                        }}>
                                            <i className="fas fa-chevron-left"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                        <p className="card-title-item">Ajouter une action</p>
                                    </Col>
                                </Row>
                            <Card.Body>
                                <div>
                                    <form onSubmit={submitHandler}>
                                        <div className="form-inner">
                                            {(error !== "") ? (<div className="error">{error}</div>) : ""}
                                            {loading ? (<div className="loader">Loading ....</div>) : (<div></div>)}
                                            <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="action">Choix de la campagne</label>
                                                        <select onChange={stateSelectionHandlerCampaign} defaultValue="">
                                                            <option ></option>
                                                            {
                                                                (campaign && campaign.length > 0) && campaign.map((schema, index) => {
                                                                    return (<option value={schema.id}
                                                                                    key={index}> {schema.name}</option>);
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="action">Choix du produit</label>
                                                        <select onChange={stateSelectionHandlerProduct}>
                                                            <option ></option>
                                                            {
                                                                (products && products.length > 0) && products.map((schema, index) => {
                                                                    return (<option value={schema.id}
                                                                                    key={index}> {schema.name}</option>);
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="action">Choix de la plateform</label>

                                                        <select onChange={stateSelectionHandlerPlatform}>
                                                            <option ></option>
                                                            {
                                                                (plateforms && plateforms.length > 0)
                                                                && plateforms.map((schema, index) => {
                                                                    return (<option value={schema.id}
                                                                                    key={index}> {schema.name}</option>);
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="action">Choix du type d'action</label>
                                                        <select onChange={stateSelectionHandlerTypes}>
                                                            <option ></option>
                                                            {
                                                                (types && types.length > 0) && types.map((schema, index) => {
                                                                    return (<option value={schema.id}
                                                                                    key={index}> {schema.name}</option>);
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="link">date publication</label>
                                                        <input type="date" max={moment().format("YYYY-MM-DD")}
                                                               name="datePub"
                                                               id="datePub"
                                                               onChange={e => setCreateAction({
                                                                   ...createAction,
                                                                   pubDate: e.target.value
                                                               })}
                                                               value={createAction.pubDate}/>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="link">Lien de la publication</label>
                                                        <input type="text" name="link" id="link"
                                                               onChange={e => setCreateAction({
                                                                   ...createAction,
                                                                   pubLink: e.target.value
                                                               })}
                                                               value={createAction.pubLink}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <label htmlFor="description">Description de la
                                                            publication</label>
                                                        <input type="text" name="description" id="description"
                                                               onChange={e => setCreateAction({
                                                                   ...createAction,
                                                                   pubDescription: e.target.value
                                                               })}
                                                               value={createAction.pubDescription}/>
                                                    </div>
                                                </Col>
                                                <Col>

                                                    <div className="form-group">
                                                        <label htmlFor="description">Joindre un fichier</label>
                                                        <input type="file" name="file"
                                                               id="file" onChange={handleInputChange}/>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <input type="submit" className="btn-fill btn-wd"
                                                   variant="info" value="Valider"/>
                                        </div>
                                    </form>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CreateAction;