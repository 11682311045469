import React, {useState} from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";
import readMessage from "../assets/img/icon/email_read.png";
import unReadMessage from "../assets/img/icon/email_new.png";

function Dashboard() {
    const uri = "/contract_list";
    const [contra, setContra] = useState(null);
    const [currentContra, setCurrentContra] = useState(null);
    const [contraLabel, setContraLabel] = useState('');
    const [loading, setLoading] = useState(true);
    let history = useHistory();

    const token = localStorage.getItem('token');
    console.log("token: " + token);
    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = contra.filter((con) => {
                return con.label.toLowerCase().includes(keyword.toLowerCase());
            });
            console.log('all contr' + contra);
            setContra(results);

            setLoading(false);
            console.log('filter' + results);
        } else {
            setContra(currentContra);
            setLoading(false);
        }

        setContraLabel(keyword);
    };

    function getData() {
        api.get(uri).then(
            res => {
                console.log(res);
                setContra(res.data);
                setCurrentContra(res.data);
                setLoading(false);
            }
        ).catch(err => {
            setLoading(false);
            alert(err.response.data.message);
        })

    }

    React.useEffect(() => {
        getData();
    }, []);
    return (
        <>
            <Container fluid>
                <div>
                    <div>
                        <Row>
                            <Col md={5}>
                                <div className="form-inner">
                                    <div className="form-group">
                                        <input type="search"
                                               value={contraLabel}
                                               onChange={filter}
                                               className="input"
                                               placeholder="Recherche"/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {loading ? (<div className="loader">Loading ....</div>) : (

                        <div>{contra && contra.length > 0 ? (
                                <div className="table-full-width">
                                    <Table>
                                        {contra && contra.length > 0 ?  (
                                            <tbody>
                                            {contra.map((contr, index) => (
                                                <tr key={index} onClick={() => {
                                                    localStorage.setItem('contraId', contr.id);
                                                    history.push('/contraDetails');
                                                }}>
                                                    <td>
                                                        <div className="icon-big text-center icon-warning">
                                                            <img className="icon-contrat"
                                                                 src={contr.logo}
                                                                 alt=""/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {contr.label}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>):<h4></h4>}
                                    </Table>
                                </div>
                        ) : <div></div>}</div>)}

                </div>
            </Container>
        </>
    );
}

export default Dashboard;
