import React, {useState} from "react";
import {SRLWrapper} from "simple-react-lightbox";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";
import background from "../assets/img/bg_influence.jpg";

function ActionDetails() {
    const uriActionDetails = "/action_detail";
    const [loading, setLoading] = useState(true);
    const [detailsOfAction, setDetailsOfAction] = useState({
        campaign: "",
        date_j: "",
        link: "",
        platform: "",
        product: "",
        text: "",
        type: ""
    });
    const [kpisValue, setKpisValue] = useState(null)
    const [actionFiles, setActionFiles] = useState(null)
    const [error, setError] = useState("");

    let history = useHistory();

    const uriTypeParam = "/actions_params";
    const uriCreateKpis = "/new_actionkpi";
    const uriForUpload = "/new_actionfile";
    const [kpisType, setKpisType] = useState(null);
    const [kpiTypeSelected, setkpiTypeSelected] = useState({id: ""});

    const [loadingUpload, setLoadingUpload] = useState(false);

    const [errorUpload, setErrorUpload] = useState("");
    const [selectedFile, setSelectedFile] = useState({selectedFile: ''});

    const token = localStorage.getItem('token');
    const actionId = localStorage.getItem('actionId');
    const contraId = localStorage.getItem('contraId');

    let viewLabel;
    const [nmbrOfView, setNmbrOfView] = useState({id: '', value:''});
    const [nmbrOfShare, setNmbrOfShare] = useState({id: '', value:''});
    const [nmbrOfComment, setNmbrOfComment] = useState({id: '', value:''});
    const [nmbrOfLike, setNmbrOfLike] = useState({id: '', value:''});

    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    const apiUpload = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });

    const [createKpis, setCreateKpis] = useState({
        idAction: "",
        idKpisType: "",
        numberOfKpis: "",
    });

    const [createKpisShare, setCreateKpisShare] = useState({
        idAction: "",
        idKpisType: "",
        numberOfKpis: "",
    });

    const [createKpisComment, setCreateKpisComment] = useState({
        idAction: "",
        idKpisType: "",
        numberOfKpis: "",
    });

    const [createKpisLike, setCreateKpisLike] = useState({
        idAction: "",
        idKpisType: "",
        numberOfKpis: "",
    });

    function getActionDetailsData() {
        api.get(uriActionDetails + "/" + actionId).then(
            res => {
                console.log(res);
                setLoading(false);
                setDetailsOfAction({
                    ...detailsOfAction,
                    date_j: res.data.action_detail.date_j,
                    link: res.data.action_detail.link,
                    text: res.data.action_detail.text,
                    type: res.data.action_detail.type,
                    product: res.data.action_detail.product,
                    campaign: res.data.action_detail.campaign,
                    platform: res.data.action_detail.platform,
                });
                setActionFiles(res.data.action_files);
                setKpisValue(res.data.action_kpis);
            }
        ).catch(err => {
            alert(err.response.data.message);
            setLoading(false);
        })

    }

    React.useEffect(() => {
        getTypeParams();
        getActionDetailsData();

        function getTypeParams() {
            api.get(uriTypeParam + "/" + contraId).then(
                res => {
                    console.log(res);
                    setKpisType(res.data.kpis);
                    setNmbrOfView({...nmbrOfView, 
                        id:res.data.kpis[0].id, 
                        value:res.data.kpis[0].label});
                    setNmbrOfShare({
                        ...nmbrOfShare,
                        id:res.data.kpis[1].id, 
                        value: res.data.kpis[1].label});
                    setNmbrOfComment({
                        ...nmbrOfComment,
                        id:res.data.kpis[2].id,
                        value:res.data.kpis[2].label
                    });
                    setNmbrOfLike({
                        ...nmbrOfLike,
                        id:res.data.kpis[3].id,
                        value:res.data.kpis[3].label
                    });
                }
            ).catch(err => {
                alert(err.response.data.message);
            })

        }
    }, []);

    const submitHandler = e => {
        e.preventDefault();
        setError("");
        setLoading(true);
        if (createKpis.numberOfKpis !== "" ||
            createKpisShare.numberOfKpis !== ""||
            createKpisComment.numberOfKpis !== ""||
            createKpisLike.numberOfKpis !== "") {

            const dataToCreateKpis = {
                action: actionId,
                kpi: nmbrOfView.id,
                value: createKpis.numberOfKpis
            };

            const dataToCreateKpisShare = {
                action: actionId,
                kpi: nmbrOfShare.id,
                value: createKpisShare.numberOfKpis
            };

            const dataToCreateKpisComment = {
                action: actionId,
                kpi: nmbrOfComment.id,
                value: createKpisComment.numberOfKpis
            };

            const dataToCreateKpisLike = {
                action: actionId,
                kpi: nmbrOfLike.id,
                value: createKpisLike.numberOfKpis
            };

            //like
                api.post(uriCreateKpis, dataToCreateKpis).then(
                    res => {
                        console.log(res);
                        setLoading(false);
                        getActionDetailsData();
                    }
                ).catch(err => {
                    setError(err.response.data.message);
                    setLoading(false);
                })
                //share
                api.post(uriCreateKpis, dataToCreateKpisShare).then(
                    res => {
                        console.log(res);
                        setLoading(false);
                        getActionDetailsData();
                    }
                ).catch(err => {
                    setError(err.response.data.message);
                    setLoading(false);
                })
                //comment
                api.post(uriCreateKpis, dataToCreateKpisComment).then(
                    res => {
                        console.log(res);
                        setLoading(false);
                        getActionDetailsData();
                    }
                ).catch(err => {
                    setError(err.response.data.message);
                    setLoading(false);
                })

                //like
                api.post(uriCreateKpis, dataToCreateKpisLike).then(
                    res => {
                        console.log(res);
                        setLoading(false);
                        getActionDetailsData();
                    }
                ).catch(err => {
                    setError(err.response.data.message);
                    setLoading(false);
                })

            
        } else {
            setLoading(false);
            setError("Renseignez les champs vide !!!");
        }
    }
    const submitHandlerForUpload = e => {
        e.preventDefault();

        setErrorUpload("");
        setLoadingUpload(true);
        const data = new FormData();
        console.log(selectedFile.selectedFile);
        if (actionId !== "" &&
            selectedFile.selectedFile !== "") {
            data.append('file', selectedFile.selectedFile)
            data.append('action', actionId);
            apiUpload.post(uriForUpload, data).then(
                res => {
                    console.log(res);
                    setLoadingUpload(false);
                    alert("Fichier Importé avec succès");
                    setSelectedFile({...selectedFile, selectedFile: ""});
                    getActionDetailsData();
                }
            ).catch(err => {
                setErrorUpload(err.response.data.message);
                alert(err.response.data.message);
                setLoadingUpload(false);
            })
        } else {
            setLoadingUpload(false);
            setErrorUpload("Renseignez les champs vide !!!");
        }
    }
    const stateSelectionHandlerKpisTypes = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateKpis({...createKpis, idKpisType: value});
        setkpiTypeSelected({...kpiTypeSelected, id: value});
        const stateIndex = kpisType.findIndex(state => state.value === value);
        console.log(event.target.value, kpisType, "index:", stateIndex);
        console.log("id product: " + kpiTypeSelected.id);
    };

    const handleInputChange = event => {
        setSelectedFile({...selectedFile, selectedFile: event.target.files[0]})
    }

    return (
        <>
            <Container fluid>

                <Card>
                    <tr>
                        <th>
                            <div className="font-icon-detail" onClick={() => {
                                history.push('/listOfAction');
                            }}>&nbsp;
                                <i className="fas fa-chevron-left"></i>
                            </div>
                        </th>
                        <th>&nbsp;&nbsp;Détails d'une action</th>
                    </tr>
                </Card>
                {loading ? (<div className="loader">Loading ....</div>) : (<div>
                    <Row>
                        <Col md="7">
                            <Card>
                                <Row>
                                    <Col>
                                        <p className="card-title-item">Détails d'une action</p>
                                    </Col>
                                    <Col lg="1" md="2" sm="2" xs="3">
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id="tooltip-488980961">
                                                    Modifier l'action
                                                </Tooltip>
                                            }
                                        >
                                            <Button
                                                className="btn-simple btn-link p-1"
                                                type="button"
                                                variant="info"
                                                onClick={() => {
                                                    history.push('/editAction');
                                                }}
                                            >
                                                <i className="fas fa-edit"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <form>
                                    <div className="form-inner">
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="link">Date création de l'action</label>
                                                    <input type="text" name="campaign" className="form-details"
                                                           id="campaign"
                                                           defaultValue={detailsOfAction.date_j}
                                                           value={detailsOfAction.date_j}/>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="link">Type de Campagne</label>
                                                    <input type="text" name="campaign"
                                                           id="campaign"
                                                           defaultValue={detailsOfAction.campaign}
                                                           value={detailsOfAction.campaign}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="link">Produit touche pas l'action</label>
                                                    <input type="text" name="campaign"
                                                           id="campaign"
                                                           defaultValue={detailsOfAction.product}
                                                           value={detailsOfAction.product}/>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="link">Plateforme ou l'action a été
                                                        posté</label>
                                                    <input type="text" name="campaign"
                                                           id="campaign"
                                                           defaultValue={detailsOfAction.platform}
                                                           value={detailsOfAction.platform}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="link">Type d'action</label>
                                                    <input type="text" name="campaign"
                                                           id="campaign"
                                                           defaultValue={detailsOfAction.type}
                                                           value={detailsOfAction.type}/>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label htmlFor="link">Description de l'action</label>
                                                    <input type="text" name="campaign"
                                                           id="campaign"
                                                           defaultValue={detailsOfAction.text}
                                                           value={detailsOfAction.text}/>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <button className="button">
                                                    <a href={detailsOfAction.link}>voir
                                                    la publication</a></button>
                                            </Col>
                                            <Col md="7">
                                                <button className="buttonRecreateAction"  onClick={() => {
                                                    history.push('/reCreateAction');
                                                }}>
                                                    Recréer l'action en modifiant la platforme</button>
                                            </Col>
                                        </Row>
                                    </div>
                                </form>
                            </Card>
                        </Col>

                        <Col lg="5" sm="6">
                            <Card className="card-stats">
                                <p className="card-title-item">KPI Renseigner</p>

                                {kpisValue && kpisValue.length > 0 ? (
                                    <div>
                                        {kpisValue.map((contr, index) => (
                                            <Card.Body>
                                                <Row>
                                                    <Col xs="5">
                                                        <div className="icon-big text-center icon-warning">
                                                            <img className="icon"
                                                                 src={contr.logo}
                                                                 alt=""/>
                                                        </div>
                                                    </Col>
                                                    <Col xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">{contr.label}</p>
                                                            <Card.Title as="h4">{contr.value}</Card.Title>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card.Body>))}
                                    </div>) : <h4></h4>}

                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" sm="6">
                            <Card className="card-stats">
                                <p className="card-title-item">Mise a jour des KPI </p>
                                <div>
                                    <form onSubmit={submitHandler}>
                                        <div className="form-inner">
                                            {(error !== "") ? (<div className="error">{error}</div>) : ""}
                                            <div className="form-group">
                                                <label htmlFor="link">{nmbrOfView.value}</label>
                                                <input type="number" name="kpisNumber"
                                                       id="kpisNumber"
                                                       onChange={e => setCreateKpis({
                                                           ...createKpis,
                                                           numberOfKpis: e.target.value
                                                       })}
                                                       value={createKpis.numberOfKpis}/>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="link">{nmbrOfShare.value}</label>
                                                <input type="number" name="kpisNumber"
                                                       id="kpisNumber"
                                                       onChange={e => setCreateKpisShare({
                                                           ...createKpisShare,
                                                           numberOfKpis: e.target.value
                                                       })}
                                                       value={createKpisShare.numberOfKpis}/>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="link">{nmbrOfComment.value}</label>
                                                <input type="number" name="kpisNumber"
                                                       id="kpisNumber"
                                                       onChange={e => setCreateKpisComment({
                                                           ...createKpisComment,
                                                           numberOfKpis: e.target.value
                                                       })}
                                                       value={createKpisComment.numberOfKpis}/>
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="link">{nmbrOfLike.value}</label>
                                                <input type="number" name="kpisNumber"
                                                       id="kpisNumber"
                                                       onChange={e => setCreateKpisLike({
                                                           ...createKpisLike,
                                                           numberOfKpis: e.target.value
                                                       })}
                                                       value={createKpisLike.numberOfKpis}/>
                                            </div>
                                            <input type="submit" className="btn-fill btn-wd"
                                                   variant="info" value="Valider"/>
                                        </div>

                                    </form>
                                </div>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6">
                            <Card className="card-stats">
                                <p className="card-title-item">Ajouter un fichier</p>
                                <Card.Body>
                                    <form onSubmit={submitHandlerForUpload}>
                                        {(errorUpload !== "") ? (<div className="error">{errorUpload}</div>) : ""}
                                        {loadingUpload ? (<div className="loader">Loading ....</div>) : (<div></div>)}
                                        <div className="form-inner">
                                            <div className="form-group">
                                                <label htmlFor="link">fichier</label>
                                                <input type="file" name="file"
                                                       id="file" onChange={handleInputChange}/>
                                            </div>
                                            <input type="submit" className="btn-fill btn-wd"
                                                   variant="info" value="Valider"/>
                                        </div>

                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4" sm="6">
                            <Card className="card-stats">
                                <p className="card-title-item">Galerie</p>
                                <Card.Body>
                                    <form>
                                        <SRLWrapper>
                                            <div className="form-inner">
                                                {actionFiles && actionFiles.length > 0 ? (
                                                    <div className="contra">
                                                        {<Row gutter={40}>
                                                            {(actionFiles).map(co =>
                                                                <Col
                                                                    xs={{span: 6}} sm={{span: 4}} md={{span: 3}}
                                                                    lg={{span: 2}} xl={{span: 5}}
                                                                >
                                                                    &nbsp;
                                                                    <a href={co.link}>
                                                                        <img src={co.link} width="60%" alt=""/>
                                                                    </a>

                                                                </Col>
                                                            )}
                                                        </Row>}

                                                    </div>
                                                ) : <div></div>}
                                            </div>
                                        </SRLWrapper>

                                    </form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>)}

            </Container>
        </>
    )
}

export default ActionDetails;