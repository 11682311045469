import React, {useState} from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";

function ListOfAction() {
    const uriLastAction = "/last_actions";
    const [actionList, setActionList] = useState(null);
    const [contraLabel, setContraLabel] = useState('');
    const [currentAction, setCurrentACtion] = useState(null);
    const [loading, setLoading] = useState(true);
    let history=useHistory();
    const token = localStorage.getItem('token');
    const contraId = localStorage.getItem('contraId');
    console.log(contraId);
    const api = axios.create({
        baseURL:"https://bo.influxs.live/StraKingAPI00X/api",
        headers: {'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',}
    });

    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = actionList.filter((con) => {
                return con.text.toLowerCase().includes(keyword.toLowerCase());
            });
            console.log('all contr'+actionList);
            setActionList(results);

            setLoading(false);
            console.log('filter'+results);
        } else {
            setActionList(currentAction);
            setLoading(false);
        }

        setContraLabel(keyword);
    };

    React.useEffect(() => {

        getLastAction();


        function getLastAction() {
            api.get(uriLastAction+"/"+contraId).then(
                res => {
                    console.log(res);
                    setActionList(res.data);
                    setCurrentACtion(res.data);
                    setLoading(false);
                }
            ).catch(err => {
                setLoading(false);
                alert(err.response.data.message);
            })

        }
    }, []);
    return (
        <>
            <Container fluid>
                <Card>
                    <tr>
                        <th>
                            <div className="font-icon-detail" onClick={() => {
                                history.replace('/contraDetails');
                            }}>&nbsp;
                                <i className="fas fa-chevron-left"></i>
                            </div>
                        </th>
                        <th>&nbsp;&nbsp;Listes des actions</th>
                    </tr>
                </Card>
                <Row>
                    <Col>
                        <Card className="card-tasks">
                                <Row>
                                    <Col lg="5" sm="5">
                                        <div className="form-inner">
                                            <div className="form-group">
                                                <input type="search"
                                                       value={contraLabel}
                                                       onChange={filter}
                                                       className="input"
                                                       placeholder="Recherche"/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" sm="4">
                                        <Button
                                            className="create-button"
                                            variant="info" onClick={() => {
                                            history.push('/createAction');
                                        }}>
                                            Ajouter une action
                                        </Button>
                                    </Col>
                                </Row>
                            <Card.Body>
                                <div className="table-full-width">
                                    <Table>
                                        {actionList && actionList.length > 0 ?  (
                                        <tbody>
                                        <tr>
                                            <th>#</th>
                                            <th>Titre de l'action</th>
                                            <th>Type action</th>
                                            <th>Date de publication</th>
                                            <th>Plateforme de publication</th>
                                            <th>détails</th>
                                        </tr>
                                        {actionList.map((contr, index) => (
                                        <tr key={index} onClick={() => {
                                            localStorage.setItem('actionId', contr.id);
                                            history.push('/actionDetails');
                                        }}>
                                            <td>
                                                <div className="icon-big text-center icon-warning">
                                                    <img className="icon"
                                                         src={contr.image}
                                                         alt=""/>
                                                </div>
                                            </td>
                                            <td>
                                                {contr.text}
                                            </td>
                                            <td>
                                                {contr.type}
                                            </td>
                                            <td>
                                                {contr.date_j}
                                            </td>
                                            <td>
                                                {contr.platform}
                                            </td>
                                            <td className="td-actions text-right">
                                                <OverlayTrigger
                                                    overlay={
                                                        <Tooltip id="tooltip-488980961">
                                                            Voir le details
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button
                                                        className="btn-simple btn-link p-1"
                                                        type="button"
                                                        variant="info"
                                                    >
                                                        <i className="fas fa-info"></i>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                        ))}
                                        </tbody>) : <h4> </h4>}
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ListOfAction;
