import React, {useState} from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
// react-bootstrap components
import {
  Alert,
  Badge,
  Button,
  Card,
  Modal,
  Navbar,
  Nav,
  Container,
  Row,
  Col, Table, OverlayTrigger, Tooltip,
} from "react-bootstrap";
import axios from "axios";
import readMessage from "../assets/img/icon/email_read.png"
import unReadMessage from "../assets/img/icon/email_new.png"
import read from "../assets/img/icon/student.png"

function Notifications() {
  const notificationAlertRef = React.useRef(null);

    const [messageList, setMessageList] = useState(null)
    const [messageId, setMessageId] = useState({messageId:""})
    const token = localStorage.getItem('token');

    const uriPlatformKpi = "/messages_list";
    const uriReadMessage = "/message";
    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    function getListOfMessage() {
        api.get(uriPlatformKpi).then(
            res => {
                console.log(res);
                setMessageList(res.data)
            }
        ).catch(err => {
            alert(err.response.data.message);
        })

    }
    function userReadMessage() {
        api.get(uriReadMessage+"/"+messageId.messageId).then(
            res => {
                console.log(res);
                alert(res.data.message);
                getListOfMessage();
            }
        ).catch(err => {
            alert(err.response.data.message);
        })

    }
    React.useEffect(() => {
        getListOfMessage();

    }, []);

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title as="h4">Notifications</Card.Title>
          </Card.Header>
          <Card.Body>
            <div className="table-full-width">
              <Table>
                  {messageList && messageList.length > 0 ?  (
                    <tbody>
                    <tr>
                      <th>#</th>
                      <th>Titre</th>
                      <th>Message</th>
                      <th>date</th>
                      <th>Lire</th>
                    </tr>
                    {messageList.map((contr, index) => (
                        <tr>
                            <td>
                                <div className="icon-big text-center icon-warning">
                                    {(contr.read_at != null) ?<img className="icon-kpi"
                                         src={readMessage}
                                         alt=""/> :<img className="icon-kpi"
                                                        src={unReadMessage}
                                                        alt=""/>}
                                </div>
                            </td>
                            <td>
                                {contr.title}
                            </td>
                            <td>
                                {contr.message}
                            </td>
                            <td>
                                {contr.created_at}
                            </td>
                            <td>
                                <img className="icon-kpi"
                                     src={read}
                                     key={index} onClick={() => {
                                    setMessageId({...messageId,messageId: contr.id})
                                    userReadMessage();
                                }}
                                     alt=""/>
                            </td>
                        </tr>
                    ))}
                    </tbody>):<h4></h4>}
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Notifications;
