import React, {useState} from "react";
import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {Pie} from "react-chartjs-2";
import comment from "../assets/img/icon/comments.png";
import eye from "../assets/img/icon/eye-color.png";
import file from "../assets/img/icon/file.png";
import share from "../assets/img/icon/share.png";
import like from "../assets/img/icon/like.png";
import action from "../assets/img/icon/action.png";

function DashboardDetailsContra() {
    const uriContraDetails = "/contract_detail";
    const uriContraKpi = "/contract_actionKPI";
    const uriPlatformKpi = "/actionsby_plateforms";
    const uriLastAction = "/last_actions";

    const [loading, setLoading] = useState(true);

    let history = useHistory();

    const [detailsContra, setDetailsContra] = useState({
        categoryLabel: "",
        companyName: "",
        images: "",
        label: "",
        nameInfluencer: "",
        scane: "",
        endDate: "",
        fullName: ""
    });


    const [kpisObjectif, setKpisObjectif] = useState(null);
    const [kpisPlatform, setKpisPlatform] = useState(null);
    const [kpisPlatformData, setKpisPlatformData] = useState({data: {}, index: ""});
    const [lastAction, setLastAction] = useState(null);
    let short_label;

    const token = localStorage.getItem('token');
    const contraId = localStorage.getItem('contraId');
    const userId = localStorage.getItem('userId');
    console.log(contraId);
    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    React.useEffect(() => {
        getContraDetailsData();
        getContraKpiObjectif();
        getPlatformResult();
        getLastAction();

        function getContraDetailsData() {
            api.get(uriContraDetails + "/" + contraId).then(
                res => {
                    console.log(res);
                    setDetailsContra({
                        ...detailsContra,
                        categoryLabel: res.data.category_label,
                        images: res.data.images,
                        fullName: res.data.full_name,
                        endDate: res.data.end_date,
                        scane: res.data.scane,
                        label: res.data.label
                    });
                }
            ).catch(err => {
                alert(err.response.data.message);
            })

        }

        function getContraKpiObjectif() {
            api.get(uriContraKpi + "/" + contraId).then(
                res => {
                    console.log(res);
                    setKpisObjectif(res.data);
                    setLoading(false);
                }
            ).catch(err => {
                setLoading(false);
                alert(err.response.data.message);
            })

        }

        function getPlatformResult() {
            api.get(uriPlatformKpi + "/" + contraId).then(
                res => {
                    console.log(res);
                    setKpisPlatform(res.data);
                    const dataPlatform = res.data;
                    console.log('is there');
                    setLoading(false);
                    let platFormName = [];
                    let platFormValue = [];
                    let indexKey;
                    dataPlatform.forEach((element, index) => {
                        platFormName.push(element.name);
                        platFormValue.push(element.value);
                        indexKey = index;
                    });

                    console.log("ssssss: " + platFormName);
                    setKpisPlatformData({
                        ...kpisPlatformData, index: indexKey, data: {

                            labels: platFormName,
                            datasets: [
                                {
                                    data: platFormValue,
                                    backgroundColor: [
                                        'rgba(255,105,145,0.6)',
                                        'rgba(155,100,210,0.6)',
                                        'rgba(90,178,255,0.6)',
                                        'rgba(240,134,67,0.6)',
                                        'rgba(120,120,120,0.6)',
                                        'rgba(250,55,197,0.6)'
                                    ]
                                }
                            ]

                        }
                    })
                }
            ).catch(err => {
                setLoading(false);
                alert(err.response.data.message);
            })

        }

        function getLastAction() {
            api.get(uriLastAction + "/" + contraId).then(
                res => {
                    console.log(res);
                    setLastAction(res.data);
                    setLoading(false);
                }
            ).catch(err => {
                setLoading(false);
                alert(err.response.data.message);
            })

        }

    }, []);
    return (
        <>
            <Container fluid>
                <Card>
                    <tr>
                        <th>
                            <div className="font-icon-detail" onClick={() => {
                                history.replace('admin/accueil');
                            }}>&nbsp;
                                <i className="fas fa-chevron-left"></i>
                            </div>
                        </th>
                        <th>&nbsp;&nbsp;Détails du contrat</th>
                    </tr>
                </Card>
                <Row>
                    <Col lg="4" sm="6">
                        <Card className="card-stats">
                            <Card.Body>
                                <Row>
                                    <Col xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <img className="Avatar"
                                                 src={detailsContra.images}
                                                 alt=""/>
                                            <div className="title-name">
                                                {detailsContra.fullName}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="7">
                                        <div className="numbers">
                                            <Card.Title as="h4">{detailsContra.label}</Card.Title>
                                            <p className="card-category">{detailsContra.categoryLabel}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4" sm="6">

                            <Card className="card-stats">
                                    <p className="card-title-item">KPI enregisté et objectif</p>
                                <hr className="dashed"/>
                                {kpisObjectif && kpisObjectif.length > 0 ? ( kpisObjectif.map((contr, index) => (
                                    <Card.Body>
                                        <Row>
                                            <Col xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    {(contr.short_label === "#VUE") ?
                                                        <img className="icon-kpi"
                                                             src={eye}
                                                             alt=""/> :
                                                        (contr.short_label === "#PUB") ?
                                                            <img className="icon-kpi"
                                                                 src={file}
                                                                 alt=""/> :
                                                            (contr.short_label === "#SHA") ?
                                                                <img className="icon-kpi"
                                                                     src={share}
                                                                     alt=""/> :
                                                                (contr.short_label === "#CMT") ?
                                                                    <img className="icon-kpi"
                                                                         src={comment}
                                                                         alt=""/> :
                                                                    (contr.short_label === "#LKE") ?
                                                                        <img className="icon-kpi"
                                                                             src={like}
                                                                             alt=""/> :
                                                                        <img className="icon-kpi"
                                                                             src={action}
                                                                             alt=""/>
                                                    }
                                                </div>
                                            </Col>
                                            <Col xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">{contr.kpi_label}</p>
                                                    <Card.Title
                                                        as="h4">{contr.kpi_actions} / {contr.contract_kpi}</Card.Title>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>))) : <h4> </h4>}
                            </Card>
                    </Col>
                    <Col lg="4" sm="6">

                            <Card className="card-stats">
                                    <p className="card-title-item">Répartition par Réseaux social</p>
                                <hr className="dashed"/>
                                {kpisPlatform && kpisPlatform.length > 0 ? (<Card.Body>
                                    <Pie
                                        key={kpisPlatformData.index}
                                        data={kpisPlatformData.data}
                                        options={{maintainAspectRatio: false}}/>
                                </Card.Body>) : <h4> </h4>}
                            </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="card-tasks">
                                <Row>
                                    <Col lg="4" sm="3">
                                        <p className="card-title-item">
                                        Listes des 3 dernières actions
                                        </p>
                                    </Col>
                                    <Col lg="3" sm="4">
                                        <Button
                                            className="create-button"
                                            variant="info" onClick={() => {
                                            history.push('/createAction');
                                        }}>
                                            Ajouter une action
                                        </Button>
                                    </Col>
                                    &nbsp;
                                    <Col lg="3" sm="4">
                                        <Button
                                            variant="default" onClick={() => {
                                            history.push('/listOfAction');
                                        }}>
                                            Voir plus d'action
                                        </Button>
                                    </Col>
                                </Row>
                            <Card.Body>
                                <div className="table-full-width">
                                    <Table>
                                        {lastAction && lastAction.length > 0 ? (
                                            <tbody>
                                            <tr>
                                                <th>#</th>
                                                <th>Titre de l'action</th>
                                                <th>Type action</th>
                                                <th>Date de publication</th>
                                                <th>Plateforme de publication</th>
                                                <th>détails</th>
                                            </tr>
                                            {lastAction.slice(0, 3).map((contr, index) => (
                                                <tr key={index} onClick={() => {
                                                    localStorage.setItem('actionId', contr.id);
                                                    history.push('/actionDetails');
                                                }}>
                                                    <td>
                                                        <div className="icon-big text-center icon-warning">
                                                            <img className="icon"
                                                                 src={contr.image}
                                                                 alt=""/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {contr.text}
                                                    </td>
                                                    <td>
                                                        {contr.type}
                                                    </td>
                                                    <td>
                                                        {contr.date_j}
                                                    </td>
                                                    <td>
                                                        {contr.platform}
                                                    </td>
                                                    <td className="td-actions text-right">
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-488980961">
                                                                    Voir le details
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button
                                                                className="btn-simple btn-link p-1"
                                                                type="button"
                                                                variant="info"
                                                                onClick={() => {
                                                                    history.push('/listOfAction');
                                                                }}
                                                            >
                                                                <i className="fas fa-info"></i>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>) : <h4> </h4>}
                                    </Table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DashboardDetailsContra;
