import React, {useState} from "react";
import {Container} from "reactstrap";
import background from "../assets/img/bg_influence.jpg";
import Logo from "../assets/img/logo.png";
import {Col, Row} from "react-bootstrap";
import {Card} from "@material-ui/core";
import axios from "axios";
import logo from "../assets/img/logo_influx.png";

function Settings() {


    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    const uriToGetUserProfile = "/get_user_profils";
    const uriForUpload = "/upload_user_profile";

    const [errorUpload, setErrorUpload] = useState("");
    const [loadingUpload, setLoadingUpload] = useState(false);

    const [selectedFile, setSelectedFile] = useState({selectedFile: ''});

    const [userProfileUri, setUserProfileUri] = useState({link:""});

    const handleInputChange = event => {
        setSelectedFile({...selectedFile, selectedFile: event.target.files[0]})
    }
    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });
    const apiUpload = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    function getUserProfile() {
        api.get(uriToGetUserProfile + "/" + userId).then(
            res => {
                console.log(res);
                setUserProfileUri({...userProfileUri, link: res.data.pop().link});
            }
        ).catch(err => {
            // alert(err.response.data.message);
        })

    }
    React.useEffect(() => {
        getUserProfile();


    }, []);
    const submitHandler = e => {
        e.preventDefault();
        const data = new FormData();
        console.log('file: '+selectedFile.selectedFile);
        if (userId !== "" &&
            selectedFile.selectedFile !== "") {
            data.append('file', selectedFile.selectedFile)
            data.append('user_id', userId);
            console.log(data);
            apiUpload.post(uriForUpload, data).then(
                res => {
                    console.log(res);
                    setLoadingUpload(false);
                    alert("Votre photo de profil a été mis a jour");
                    setSelectedFile({...selectedFile, selectedFile: ""});
                    getUserProfile();
                }
            ).catch(err => {
                setErrorUpload(err.response.data.message);
                alert(err.response.data.message);
                setLoadingUpload(false);
            })
        } else {
            setLoadingUpload(false);
            setErrorUpload("Veuillez joindre un fichier");
        }
    }

    return(
        <>
            <Container fluid>
                <form onSubmit={submitHandler}>
                    <div className="form-inner">
                        <img className="Avatar"
                             src={userProfileUri.link !== "" ? userProfileUri.link :logo}
                             alt=""/>
                        <div className="form-group">

                            <br/>
                            <label htmlFor="description">Modifier votre photo de profil</label>
                            <input type="file" name="file"
                                   id="file" onChange={handleInputChange}/>
                        </div>
                        <input type="submit" className="btn-fill btn-wd"
                               variant="info" value="Valider"/>
                    </div>

                </form>
            </Container>
        </>
    )
}
export default Settings;