/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import Login from "./layouts/Login";
import DashboardDetailsContra from "./views/DashboardDetailsContra";
import ListOfAction from "./views/ListOfAction";
import CreateAction from "./views/CreateAction";
import ReCreateAction from "./views/ReCreateAction";
import ActionDetails from "./views/ActionsDetails";
import EditAction from "./views/EditAction";
import SimpleReactLightbox from 'simple-react-lightbox'

ReactDOM.render(
    <SimpleReactLightbox>
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/contraDetails" component={DashboardDetailsContra}/>
                <Route path="/listOfAction" component={ListOfAction}/>
                <Route path="/createAction" component={CreateAction}/>
                <Route path="/reCreateAction" component={ReCreateAction}/>
                
                <Route path="/actionDetails" component={ActionDetails}/>

                <Route path="/editAction" component={EditAction}/>
                <Route path="/admin" render={(props) => <AdminLayout {...props} />}/>
                <Redirect from="/" to="/login"/>
            </Switch>
        </BrowserRouter>,
    </SimpleReactLightbox>,
document.getElementById("root")
)
;
