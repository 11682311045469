import React, {useState} from "react";
import moment from 'moment';
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import axios from "axios";

function ReCreateAction() {
    const uriTypeParam = "/actions_params";
    const uriToCreateAction = "/new_action";
    const uriActionDetails = "/action_detail";
    const [plateforms, setPlateforms] = useState(null);
    const [plateformSelected, setPlateformSelected] = useState({id: ""});
    const [campaign, setCampaign] = useState(null);
    const [campaignSelected, setCampaignSelected] = useState({id: ""});
    const [products, setProducts] = useState(null);
    const [productsSelected, setProductsSelected] = useState({id: ""});
    const [types, setTypes] = useState(null);
    const [typesSelected, setTypesSelected] = useState({id: ""});

    let history = useHistory();

    const [loading, setLoading] = useState(false);
    const [loadingInit, setLoadingInit] = useState(false);

    const [error, setError] = useState("");

    let idPlateform;
    let idProduct;
    let idCampaign;
    let idActionType;
    let campaignList = [];
    let actionTypeList = [];
    let productList = [];
    let platFormList = [];

    const [createAction, setCreateAction] = useState({
        idPlateform: "",
        idProduct: "",
        idCampaign: "",
        idActionType: "",
        pubDate: "",
        pubLink: "",
        pubDescription: "",
    });
    const token = localStorage.getItem('token');
    const contraId = localStorage.getItem('contraId');
    const actionId = localStorage.getItem('actionId');

    const editActionIntention = localStorage.getItem('editIntention');

    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api",
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
        }
    });

    React.useEffect(() => {
        getTypeParams();

        function getTypeParams() {
            api.get(uriTypeParam + "/" + contraId).then(
                res => {
                    console.log(res);
                    campaignList = res.data.campagns;
                    actionTypeList = res.data.types;
                    productList = res.data.products;
                    platFormList =res.data.plateforms;
                    setCampaign(res.data.campagns);
                    setProducts(res.data.products);
                    setPlateforms(res.data.plateforms);
                    setTypes(res.data.types);

                    getActionDetailsData();
                }
            ).catch(err => {
                alert(err.response.data.message);
            })

        }

    }, []);

    function getActionDetailsData() {
        api.get(uriActionDetails + "/" + actionId).then(
            res => {
                console.log(res);
                setLoading(false);
                setDetailsOfAction({
                    ...detailsOfAction,
                    date_j: res.data.action_detail.date_j,
                    link: res.data.action_detail.link,
                    text: res.data.action_detail.text,
                    type: res.data.action_detail.type,
                    product: res.data.action_detail.product,
                    campaign: res.data.action_detail.campaign,
                    platform: '',
                });

                campaignList.forEach(element =>{
                    if(element.name === res.data.action_detail.campaign){
                        idCampaign = element.id;
                    }
                });
                actionTypeList.forEach(element =>{
                    if(element.name === res.data.action_detail.type){
                        idActionType = element.id;
                    }
                });
                productList.forEach(element =>{
                    if(element.name === res.data.action_detail.product){
                        idProduct = element.id;
                    }
                });
                /*platFormList.forEach(element =>{
                    if(element.name === res.data.action_detail.platform){
                        idPlateform = element.id;
                    }
                });*/
                setCreateAction({
                    ...createAction,
                    pubDate: res.data.action_detail.date_j,
                    pubDescription: res.data.action_detail.text,
                    pubLink: res.data.action_detail.link,
                    idCampaign: idCampaign,
                    idActionType: idActionType,
                    idPlateform: '',
                    idProduct: idProduct,
                })

                setLoadingInit(true);

            }
        ).catch(err => {
            alert(err.response.data.message);
            setLoading(false);

            setLoadingInit(true);
        })

    }
    const [detailsOfAction, setDetailsOfAction] = useState({
        campaign: "",
        date_j: "",
        link: "",
        platform: "",
        product: "",
        text: "",
        type: ""
    });

    const submitHandler = e => {
        e.preventDefault();
        setError("");
        setLoading(true);
        if (createAction.pubDate !== "" &&
            createAction.pubDescription !== "" &&
            createAction.idProduct !== "" &&
            createAction.idActionType !== "" &&
            createAction.idCampaign !== "" &&
            createAction.idPlateform !== "" &&
            createAction.pubLink !== "") {
            const dataToCreateAction = {
                contract: contraId,
                platform: createAction.idPlateform,
                product: createAction.idProduct,
                campaign: createAction.idCampaign,
                action_type: createAction.idActionType,
                date_pub: createAction.pubDate,
                link: createAction.pubLink==="" ?"actionDetails":createAction.pubLink,
                text: createAction.pubDescription ===""?"R.A.S":createAction.pubDescription,
                tags: ""
            };
            console.log(dataToCreateAction);
            api.post(uriToCreateAction, dataToCreateAction).then(
                res => {
                    console.log(res);
                    setLoading(false);
                    alert(res.data.message);
                    history.replace('/listOfAction');
                }
            ).catch(err => {
                setError(err.response.data.message);
                alert(err.response.data.message);
                setLoading(false);
            })
        } else {
            setLoading(false);
            setError("Renseignez les champs vide !!!");
        }
    }


    const stateSelectionHandlerCampaign = event => {
        const value = event.target.value;
        setCreateAction({...createAction, idCampaign: value});
        setCampaignSelected({...campaignSelected, id: value});
        const stateIndex = campaign.findIndex(state => state.value === value);
        console.log(event.target.value, campaign, "index:", stateIndex);

        console.log("id campaign: " + campaignSelected.id);
    };
    const stateSelectionHandlerProduct = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateAction({...createAction, idProduct: value});
        setProductsSelected({...productsSelected, id: value});
        const stateIndex = products.findIndex(state => state.value === value);
        console.log(event.target.value, products, "index:", stateIndex);
        console.log("id product: " + productsSelected.id);
    };

    const stateSelectionHandlerPlatform = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateAction({...createAction, idPlateform: value});
        setPlateformSelected({...plateformSelected, id: value});
        const stateIndex = plateforms.findIndex(state => state.value === value);
        console.log(event.target.value, plateforms, "index:", stateIndex);
        console.log("id product: " + plateformSelected.id);
    };
    const stateSelectionHandlerTypes = event => {
        const value = event.target.value;
        console.log("id: " + value);
        setCreateAction({...createAction, idActionType: value});
        setTypesSelected({...typesSelected, id: value});
        const stateIndex = types.findIndex(state => state.value === value);
        console.log(event.target.value, types, "index:", stateIndex);
        console.log("id product: " + typesSelected.id);
    };
    return (
        <>
            <Container fluid>
                <Row className="login_form">
                    <Col md="7">
                        <Card>
                                <Row>
                                    <Col className="font-icon-list" lg="1" md="2" sm="2" xs="3">
                                        <div className="font-icon-detail" onClick={() => {
                                            history.replace('/actionDetails');
                                        }}>
                                            <i className="fas fa-chevron-left"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                        <p className="card-title-item">Ajouter une nouvelle action</p>
                                    </Col>
                                </Row>
                            <Card.Body>
                                <div>
                                    {  !loadingInit ? (<div className="loader">Loading ....</div>) : (<div>
                                        <form onSubmit={submitHandler}>
                                            <div className="form-inner">
                                                {(error !== "") ? (<div className="error">{error}</div>) : ""}
                                                {loading ? (<div className="loader">Loading ....</div>) : (<div></div>)}
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="action">Choix de la campagne</label>

                                                            <select onChange={stateSelectionHandlerCampaign}>
                                                                <option  value={idCampaign}>{detailsOfAction.campaign}</option>
                                                                {
                                                                    (campaign && campaign.length > 0) && campaign.map((schema, index) => {
                                                                        return (<option value={schema.id}
                                                                                        key={index}> {schema.name}</option>);
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="action">Choix du produit</label>

                                                            <select onChange={stateSelectionHandlerProduct}>
                                                                <option value={idProduct}>{detailsOfAction.product}</option>
                                                                {
                                                                    (products && products.length > 0) && products.map((schema, index) => {
                                                                        return (<option value={schema.id}
                                                                                        key={index}> {schema.name}</option>);
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="action">Choix de la plateform</label>

                                                            <select onChange={stateSelectionHandlerPlatform}>
                                                                <option value={idPlateform}>{detailsOfAction.platform}</option>
                                                                {
                                                                    (plateforms && plateforms.length > 0) && plateforms.map((schema, index) => {
                                                                        return (<option value={schema.id}
                                                                                        key={index}> {schema.name}</option>);
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="action">Choix du type d'action</label>
                                                            <select onChange={stateSelectionHandlerTypes}>
                                                                <option value={idActionType}>{detailsOfAction.type}</option>
                                                                {
                                                                    (types && types.length > 0) && types.map((schema, index) => {
                                                                        return (<option value={schema.id}
                                                                                        key={index}> {schema.name}</option>);
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="link">date publication</label>
                                                            <input type="date" max={moment().format("YYYY-MM-DD")}
                                                                   name="datePub"
                                                                   id="datePub"
                                                                   onChange={e => setCreateAction({
                                                                       ...createAction,
                                                                       pubDate: e.target.value
                                                                   })}
                                                                   value={createAction.pubDate}/>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="link">Lien de la publication</label>
                                                            <input type="text" name="link" id="link"
                                                                   onChange={e => setCreateAction({
                                                                       ...createAction,
                                                                       pubLink: e.target.value
                                                                   })}
                                                                   value={createAction.pubLink}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group">
                                                            <label htmlFor="description">Description de la
                                                                publication</label>
                                                            <input type="text" name="description" id="description"
                                                                   onChange={e => setCreateAction({
                                                                       ...createAction,
                                                                       pubDescription: e.target.value
                                                                   })}
                                                                   value={createAction.pubDescription}/>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <input type="submit" className="btn-fill btn-wd"
                                                       variant="info" value="Valider"/>
                                            </div>
                                        </form>
                                    </div>)
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ReCreateAction;