import React, {Component, useState} from "react";
import {useHistory} from "react-router-dom";
import axios from 'axios';
import {Col, Row} from "react-bootstrap";
import Logo from "../assets/img/logo.png"

import background from "../assets/img/bg_influence.jpg";

function Login() {
    const uri = "/login";
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");

    const [details, setDetails] = useState({username: "", password: ""});
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    //
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    let history=useHistory();

    const api = axios.create({
        baseURL: "https://bo.influxs.live/StraKingAPI00X/api"
    });
    //
    const submitHandler = e => {
        e.preventDefault();
        setError("");
        setLoading(true);
        if(details.username !=="" && details.password !== ""){
            const  dataToLogin = {
                login: details.username,
                password:details.password
            };
            api.post(uri,dataToLogin).then(
                res=>{
                    console.log(res);
                    localStorage.setItem('token',res.data.access_token);
                    localStorage.setItem('userId',res.data.user.id);
                    setLoading(false);
                    if(res.data.access_token !== null){
                        history.replace('/admin/accueil');
                    }
                }
            ).catch(err =>{
                setError(err.response.data.message);
                alert(err.response.data.message);
                setLoading(false);
            })
        }else {
            setLoading(false);
            setError("Renseignez votre nom d'utilisateur et votre mot de passe");
        }
    }

    return (
        <div className="login_form" style={{ backgroundImage: `url(${background})`}}>
            <form onSubmit={submitHandler}>
                <div className="form-inner">
                    <img src={Logo} alt="Logo" className="logo"/>
                    {(error!=="") ? (<div className="error">{error}</div>) : ""}

                    {loading ? (<div className="loader">Loading ....</div>) :(<div> </div>)}

                    <div className="form-group-login">
                        <label htmlFor="userName">Nom d'utilisateur</label>
                        <input type="text" name="userName" id="userName"
                               onChange={e => setDetails({...details, username: e.target.value})}
                               value={details.username}/>
                    </div>
                    <div className="form-group-login">
                        <label htmlFor="password">Password</label>
                        <input
                            type={values.showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            onChange={e => setDetails({...details, password: e.target.value})}
                            value={details.password}
                        />
                    </div>
                    &nbsp;
                    <Row>
                        <Col>
                            <input type="submit" value="Connexion"/>
                        </Col>
                        <Col>
                            <a href="https://bo.influxs.live/StraKingAPI00X/phone-auth">Mot de passe oublié ?</a>
                        </Col>
                    </Row>
                </div>

            </form>
        </div>
    )
}

export default Login;